exports.components = {
  "component---src-components-common-full-blog-page-index-js": () => import("./../../../src/components/common/fullBlogPage/index.js" /* webpackChunkName: "component---src-components-common-full-blog-page-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-blog-page-index-js": () => import("./../../../src/pages/blogPage/index.js" /* webpackChunkName: "component---src-pages-blog-page-index-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-faqs-index-js": () => import("./../../../src/pages/faqs/index.js" /* webpackChunkName: "component---src-pages-faqs-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-individuals-index-js": () => import("./../../../src/pages/individuals/index.js" /* webpackChunkName: "component---src-pages-individuals-index-js" */),
  "component---src-pages-measure-manage-index-js": () => import("./../../../src/pages/measure&manage/index.js" /* webpackChunkName: "component---src-pages-measure-manage-index-js" */),
  "component---src-pages-measure-manage-js": () => import("./../../../src/pages/measureManage.js" /* webpackChunkName: "component---src-pages-measure-manage-js" */),
  "component---src-pages-offsets-js": () => import("./../../../src/pages/offsets.js" /* webpackChunkName: "component---src-pages-offsets-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-resources-index-js": () => import("./../../../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-terms-index-js": () => import("./../../../src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */),
  "component---src-pages-visualizes-engage-index-js": () => import("./../../../src/pages/visualizes&engage/index.js" /* webpackChunkName: "component---src-pages-visualizes-engage-index-js" */)
}

